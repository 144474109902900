import React, { useState } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'
import toast, { Toaster } from 'react-hot-toast'
import Link from 'next/link'
import Cookie from 'js-cookie'
import { redirectToHomeIfAuthenticated } from '../../utils/auth'

// Trang đăng nhập
export async function getServerSideProps(context) {
  return redirectToHomeIfAuthenticated(context)
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const router = useRouter() // Initialize useRouter

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.NEXT_PUBLIC_SERVER_URL}/auth/login`,
        { email, password }
      )

      localStorage.setItem('username', response.data.user.username)

      // Lưu token vào cookies
      Cookie.set('token', response.data.token, {
        expires: 1,
        // eslint-disable-next-line no-undef
        secure: process.env.NODE_ENV === 'production',
        // sameSite: 'strict',
        //NOTE production
        sameSite: 'lax', // 'lax' cho phép cookies được gửi khi điều hướng từ liên kết
        domain: '.wolfteam.vn', // Thay 'example.com'
      })
      setMessage('Login successful')
      toast.success('Đăng nhập thành công!')

      // Redirect or navigate to another page after login
      router.push('/admindashboard/homeindex')
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const message = error.response.data.message
        toast.error(message) // Hiển thị thông báo lỗi từ server
      } else {
        toast.error('Đã có lỗi xảy ra, vui lòng thử lại sau.')
      }
    }
  }

  // Hàm xử lý khi nhấn phím Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <>
      <Toaster />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className=" d-flex align-items-center auth px-0 mt-5">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src="/wolfteam.png" alt="logo" />
                  </div>
                  <h4>Xin chào! hãy bắt đầu</h4>
                  <h6 className="font-weight-light">Tiếp tục đăng nhập.</h6>
                  <p>{message}</p>
                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Địa chỉ email"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Mật khẩu"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    <div className="mt-3">
                      <a
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        href="#"
                        onClick={handleLogin}
                      >
                        Đăng nhập
                      </a>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <Link
                        href="/forgotpassword"
                        className="auth-link text-primary"
                      >
                        Quên mật khẩu?
                      </Link>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      Chưa có tài khoản?{' '}
                      <Link href="/register" className="text-primary">
                        Tạo mới
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
